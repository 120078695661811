<template>
  <div class="container fluid">
    <div class="row mb-3">
      <div class="col-md-4 d-flex">
        <input
          type="text"
          v-model="searchTerm"
          @input="updateList"
          class="form-control me-2"
          style="min-width: 250px;"
          placeholder="Search treatments"
        />
        <select
          v-model="retreat"
          @input="updateList"
          class="form-control me-2"
          style="min-width: 250px;"
        >
          <option value="">All retreats</option>
          <option value="uk">UK</option>
          <option value="malta">Malta</option>
          <option value="both">Both</option>
        </select>
        <select
          v-model="status"
          @input="updateList"
          class="form-control"
          style="min-width: 250px;"
        >
          <option value="">All statuses</option>
          <option value="draft">Draft</option>
          <option value="on_hold">On hold</option>
          <option value="published">Published</option>
        </select>
      </div>

      <div class="col-auto ms-auto">
        <router-link
          class="btn btn btn-outline-success btn-sm"
          to="/treatments/create"
        >
          <i class="far fa-plus me-2"></i>Add New
        </router-link>
      </div>
    </div>
    <div class="card border shadow-none" v-if="!loading">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Details</th>
                    <th scope="col">Retreat</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="t in treatments"
                    :key="t.id"
                    @click="viewTreatment(t)"
                    class="cursor-pointer"
                  >
                    <td>
                      <img
                        :src="t.image"
                        :alt="t.title"
                        height="100px"
                      />
                    </td>
                    <td>{{ t.title }}</td>
                    <td v-html="getSummaryFormatted(t)"></td>
                    <td>
                      {{ getRetreatFormatted(t) }}
                    </td>
                    <td style="text-transform: capitalize;">
                      {{ t.status | formatStatus }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="pagination">
              <div class="left">
                <button
                  @click="fetchTreatments(pagination.first_page_url)"
                  :disabled="!pagination || !pagination.first_page_url
                    || pagination.current_page == 1"
                  class="btn btn-light btn-sm me-3"
                >
                  First Page
                </button>

                <button
                  @click="fetchTreatments(pagination.prev_page_url)"
                  :disabled="!pagination || !pagination.prev_page_url"
                  class="btn btn-light btn-sm"
                >
                  Previous
                </button>
              </div>

              <div class="middle">
                Page {{ pagination ? pagination.current_page : 1 }}
                of
                {{ pagination ? pagination.last_page: 1 }}
              </div>

              <div class="right">
                <button
                  @click="fetchTreatments(pagination.next_page_url)"
                  :disabled="!pagination || !pagination.next_page_url"
                  class="btn btn-light btn-sm me-3"
                >
                  Next
                </button>

                <button
                  @click="fetchTreatments(pagination.last_page_url)"
                  :disabled="!pagination || !pagination.last_page_url
                    || pagination.current_page == pagination.last_page"
                  class="btn btn-light btn-sm"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
  import debounce from "debounce";
  import Loading from "../../components/loaders/Loading";

  export default {
    data() {
      return {
        loading: true,
        pagination: [],
        retreat: "",
        searchTerm: "",
        status: "",
        treatments: []
      };
    },
    methods: {
      fetchTreatments: debounce(function(paginationUrl) {
        let url = process.env.VUE_APP_API_URL + "/treatments";

        if (paginationUrl) {
          url = paginationUrl;
        }

        if (this.retreat) {
          url = this.updateQueryStringParameter(url, "retreat", this.retreat);
        }

        if (this.searchTerm) {
          url = this.updateQueryStringParameter(url, "search_term", this.searchTerm);
        }

        if (this.status) {
          url = this.updateQueryStringParameter(url, "status", this.status);
        }

        this.loading = true;

        this.$axios.get(url).then(({ data }) => {
          this.pagination = data;
          this.treatments = data.data;
          this.loading = false;
        });
      }, 300),

      getRetreatFormatted(t) {
        switch (t.retreat) {
          case "uk":
            return "UK";
          case "malta":
            return "Malta";
          case "both":
            return "Both";
        }
      },

      getSummaryFormatted(t) {
        if (t.retreat === "malta" && t.euro_price) {
          return `${t.duration} min session / &euro; ${t.euro_price.toFixed(2)}`;
        } else if (t.price) {
          return `${t.duration} min session / &pound; ${t.price.toFixed(2)}`;
        } else {
          return `${t.duration} min session`;
        }
      },

      updateList: debounce(function() {
        this.fetchTreatments();
      }, 300),

      updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";

        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      },

      viewTreatment(treatment) {
        this.$router.push("/treatments/" + treatment.id);
      },
    },
    mounted() {
      this.$store.sidebarOpen = false;
    },
    created() {
      this.fetchTreatments();
    },
    filters: {
      formatStatus: function (status) {
        if (status) {
          return status.replace(/_/g, " ");
        }
        return "unknown";
      },
    },
    components: {
      Loading,
    },
  };
</script>

<style>
  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .pagination button {
    margin: 0 0.5rem;
  }
</style>